import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-46be6ab2"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "login-container"
};
const _hoisted_2 = {
  class: "left-box"
};
const _hoisted_3 = {
  class: "right-box"
};
import TreeMap from './threeMap.vue';
import QQMap from './myQQMap.vue';
import { onMounted, ref } from 'vue';
export default {
  __name: 'index',

  setup(__props) {
    onMounted(() => {
      initFlag.value = true;
    });
    const initFlag = ref(false);
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [initFlag.value ? (_openBlock(), _createBlock(TreeMap, {
        key: 0
      })) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_3, [_createVNode(QQMap)])]);
    };
  }

};